import $ from "jquery";
import moment from "moment-timezone";
import consumer from "./consumer";
import { getCSRFTokenValue } from "../CsrfToken";

let lastTimeoutData;
let notifier;
let alertTime;

const touchSession = () => {
  $.ajax({
    url: "/touch_sessions/new",
    type: "get",
    dataType: "JSON",
    headers: {
      "X-CSRF-Token": getCSRFTokenValue(),
    },
    success() {},
  });
};
window.touchSession = touchSession;

const showTimeout = () => {
  $(".coupled.modal").modal({ allowMultiple: true });
  $("#timeout-modal .countdown")
    .countdown(new Date(lastTimeoutData.timeout_in), (e) => {
      $(e.target).html(e.strftime("%H hours %M minutes %S seconds"));
    })
    .on("finish.countdown", () => {
      let logoutUrl = $("#timeout-modal").data("logout-path");

      if (lastTimeoutData.redirect_url) {
        logoutUrl = `${logoutUrl}?redirect_to=${encodeURIComponent(lastTimeoutData.redirect_url)}`;
      }

      window.location.href = logoutUrl;
    });

  $("#timeout-modal").modal("show");
};

const resetTimeout = () => {
  clearTimeout(notifier);
  $("#timeout-modal .countdown").off("finish.countdown");
  const startTime = moment(lastTimeoutData.timeout_in)
    .utc("YYYY-MM-DD HH:mm:ss")
    .subtract(5, "minutes");
  const endTime = moment().utc("YYYY-MM-DD HH:mm:ss");
  alertTime = startTime - endTime;
  notifier = setTimeout(showTimeout, alertTime);
};

$(window).on("focus", () => {
  if (notifier) {
    resetTimeout();
  }
});

export const init = () => {
  window.App.Timeout = consumer.subscriptions.create("TimeoutChannel", {
    connected() {
      touchSession();
    },
    received(data) {
      lastTimeoutData = data;

      if (lastTimeoutData.test_mode) {
        clearTimeout(notifier);
        return;
      }
      resetTimeout();
    },
  });
};

document.addEventListener("timeout:init", () => {
  init();
});

document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector("html").hasAttribute("data-timeout")) {
    init();
  }
});
