import $ from 'jquery';
import consumer from './consumer';

document.addEventListener('importer:init', () => {
  const importerSubscription = consumer.subscriptions.create('ImporterChannel', {
    received(data) {
      const { errors, alerts, success } = data;

      let modal = null;
      if (success) {
        modal = $(`.${data.id}.success`);
        if (data.count_action) {
          if (!data.count.default || data.count.default <= 0) {
            modal.find('.success-no-import').removeClass('hide');
          } else {
            Object.keys(data.count).forEach((key) => {
              if (data.count[key] > 0) {
                modal.find(`.count.${key} .record-count`).text(`${data.count[key]} `);
                modal.find(`.count.${key}`).removeClass('hide');
              }
            });
            if (data.post_action) {
              modal.find('.post-action').removeClass('hide');
            }
            modal.find('.success').removeClass('hide');
          }
        } else if (data.count <= 0) {
          modal.find('.success-no-import').removeClass('hide');
        } else {
          modal.find('.success').prepend(`${data.count} `).removeClass('hide');
          if (data.post_action) {
            modal.find('.post-action').removeClass('hide');
          }
        }
        if (alerts && alerts.length > 0) {
          modal.find('.alerts').removeClass('hide');

          let alertsContent = '';
          for (const i in alerts) {
            alertsContent += `<li>${alerts[i]}</li>`;
          }
          modal.find('.alerts-list').removeClass('hide').append(alertsContent);
        }
      } else {
        modal = $(`.${data.id}.failed`);

        let errorsContent = '';
        for (const line in errors) {
          errorsContent += `<li>Line ${line}: ${errors[line].join(', ')}</li>`;
        }
        modal.find('.errors-list').removeClass('hide').append(errorsContent);
      }

      const modalOptions = {
        closable: true,
        observeChanges: true,
        autofocus: false,
        selector: {
          close: '.close',
        },
      };

      modal
        .modal(modalOptions)
        .modal('show')
        .modal('refresh');

      if (importerSubscription != null) {
        consumer.subscriptions.remove(importerSubscription);
        consumer.disconnect();
      }
    },
  });

  document.querySelectorAll('[data-file-name-display]').forEach((el) => {
    const displayField = document.querySelector(el.dataset.fileNameDisplay);
    if (displayField) {
      el.addEventListener('change', (e) => {
        displayField.innerText = e.target.files[0].name;
      });
    }
  });
});
