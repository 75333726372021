import consumer from './consumer';

import toastr from '../../plugins/Toastr';

document.addEventListener('notifications:init', () => {
  if (window.App.Notifications == null) {
    window.App.Notifications = consumer.subscriptions.create({
      channel: 'NotificationsChannel',
    }, {
      received(data) {
        if (data.title !== null) {
          toastr.remove();
          switch (data.style) {
            case 'success': toastr.success(data.body, data.title); break;
            case 'error': toastr.error(data.body, data.title); break;
            case 'warning': toastr.warning(data.body, data.title); break;
            case 'info': toastr.info(data.body, data.title); break;
            default: toastr.info(data.body, data.title); break;
          }
        }
      },
    });
  }
});
